body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  font-family: Gotham Rounded A, Gotham Rounded B, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #4d5659;
  text-rendering: optimizeSpeed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[hidden] {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

p {
  margin-top: 0;
  margin-bottom: 30px;
}

img,
video {
  max-width: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}
